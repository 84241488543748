@import '../../../../../or_base/scss/theme-bootstrap';

.waitlist-form {
  max-width: 425px;
  height: auto;
  margin: auto;
  text-align: center;
  &__header {
    @include font--subheading;
    color: $color-green;
    text-transform: uppercase;
    font-size: 55px;
    margin-bottom: 14px;
    &.temp-out-of-stock {
      padding-top: 10%;
      font-size: 35px;
    }
  }
  &__message {
    line-height: 1.5;
    font-size: 16px;
  }
  input.waitlist-form__email {
    width: 265px;
  }
}
